body {
  background-color: #ffffff;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  min-width: 400px;
}

html,
body,
div.app,
#app>div {
  height: 100%
}

input:checked~.dot {
  transform: translateX(100%);
}

input:checked~.checkback {
  background-color: #38c172;
}

.cookie-table,
tr,
td {
  padding: 8px;
  border: 1px solid rgb(82, 82, 82);
}